<template>
    <v-layout column="" row wrap pr-3 mr-2 mt-1>

        <v-dialog v-model="dialog" width="850" persistent>
            <v-card pa-4>
                {{editedItem.name}}
                <v-form ref="form">


                    <v-card-title class="headline grey lighten-2" primary-title
                        style="	font-family: 'droid_bold' !important;    background-color: #435c6f!important;color:#fff">

                        <span v-if="editedIndex==1">تعديل التاجر</span>
                        <span v-else>اضافه تاجر</span>
                    </v-card-title>

                    <v-container>
                        <v-col>
                            <v-row justify="center" class="mb-3">

                                <!-- <v-avatar height="100" width="100">
                                    <img class="signup_img" :src="images[0]" :alt="imgname">
                                </v-avatar> -->

                            </v-row>
                            <!-- <v-row justify="center">
                                <v-btn dark color="#435c6f" @click="pickFile">

                                    <v-icon size="25" style="padding-left :7px">fas fa-camera</v-icon>
                                    صورة
                                </v-btn>
                            </v-row> -->


                        </v-col>
                        <v-flex pa-3 xs12>


                            <v-lable>اسم التأجر</v-lable>
                            <v-text-field filled background-color="#eeeeee" v-model="editedItem.user.full_name"
                                height="40" style="" placeholder="اسم التأجر" required></v-text-field>


                        </v-flex>
                        <v-flex pa-3 xs12>

                            <v-lable>اسم الشركة</v-lable>
                            <v-text-field background-color="#eeeeee" v-model="editedItem.owner_barnd_name" height="40"
                                style="" filled placeholder="اسم الشركة" required :rules="nameRules"></v-text-field>
                        </v-flex>



                        <v-flex pa-3 xs12 v-if="editedIndex!==1">

                            <v-lable>رقم الهاتف</v-lable>
                            <v-text-field background-color="#eeeeee" v-model="editedItem.owner_phone" height="40"
                                style="" filled placeholder="رقم الهاتف" required :rules="phoneRules"></v-text-field>
                        </v-flex>

                        <v-flex pa-3 xs12 v-else>
                            <v-lable>رقم الهاتف</v-lable>
                            <v-text-field background-color="#eeeeee" v-model="editedItem.owner_phone" height="40"
                                disabled style="" filled placeholder="رقم الهاتف"></v-text-field>

                        </v-flex>



                        <!-- <v-flex pa-3 xs12>
                            <v-lable>الايميل</v-lable>
                            <v-text-field background-color="#eeeeee" v-model="editedItem.owner_email" height="40"
                                style="" filled placeholder="الايميل" required :rules="emailRules"></v-text-field>
                        </v-flex> -->


                        <v-flex pa-3 xs12 v-if="editedIndex!==1">
                            <v-lable>الباسورد</v-lable>
                            <v-text-field background-color="#eeeeee" v-model="editedItem.password"
                                :type="show1 ? 'text' : 'password'"
                                :append-icon="show1 ? 'visibility' : 'visibility_off'" @click:append="show1 = !show1"
                                height="40" style="" filled placeholder="الباسورد" required :rules="passwordRules">
                            </v-text-field>
                        </v-flex>


                        <v-flex pa-3 xs12 v-if="editedIndex!==1">
                            <v-lable>اعاده كتابه الباسورد</v-lable>
                            <v-text-field background-color="#eeeeee" v-model="editedItem.password_confirmation"
                                height="40" :type="show2 ? 'text' : 'password'"
                                :append-icon="show2 ? 'visibility' : 'visibility_off'" @click:append="show2 = !show2"
                                filled placeholder="اعاده كتابه الباسورد" required :rules="passwordConfirmationRules">
                            </v-text-field>
                        </v-flex>





                        <v-flex pa-3 xs12>
                            <v-lable> التصنيف</v-lable>


                            <v-select background-color="#eeeeee" :items="category" item-text="name" item-value="id"
                                filled placeholder=" التصنيف" required height="40"
                                v-model="editedItem.owner_category.category_id">
                            </v-select>
                        </v-flex>




                        <v-flex xs12 pa-3 v-if="editedIndex>=1">



                            <div v-if="editedItem.user.status.id==2">
                                <v-alert type="error" style="position: relative;right: 0%;width: 84%;">
                                    <span> تم ارسال رقم التحقق للتاجر ولم يتم تفعيله </span>

                                </v-alert>
                                <v-btn color="primary" @click="verfy_Dailog=true,signup_user_id=editedItem.user.id">
                                    تفعيل </v-btn>

                            </div>
                            <v-radio-group d v-else required v-model="editedItem.user.status.id">
                                <v-radio label="مفعل" :value="1"></v-radio>
                                <v-radio label="ايقاف" :value="24"></v-radio>

                            </v-radio-group>

                            <v-radio-group required v-if="itemOwnerSubscriptions==null"
                                :rules="PackagRules" v-model="editedItem.OwnerSubscriptions.subscription_package_id">
                                <v-layout row wrap pa-4>

                                    <v-flex pt-2 xs12 v-for="item in  subscriptions" :key="item.id">
                                        <v-card style="padding:10px;width:100%">

                                            <v-radio
                                                :label=" item.package_name+' | عدد الحجوزات  '+item.reservations_count +'| السعر '+item.price_value+'دينار'"
                                                :value="item.id">


                                                <template v-slot:label>

                                                    <v-layout row wrap>
                                                        <v-flex xs2 md2 sm2 pr-5>
                                                            <div style="text-align:right;"> <strong
                                                                    style="color:green">{{item.package_name}} </strong>
                                                            </div>
                                                        </v-flex>

                                                        <v-flex pr-3 xs5 md3 sm3>
                                                            <div><span style="font-weight:bold">عدد الحجوزات
                                                                </span> <strong style="color:blue;padding-right:10px">
                                                                    {{item.reservations_count}} </strong> </div>
                                                        </v-flex>

                                                        <v-flex xs1 md3 sm3>

                                                        </v-flex>

                                                        <v-flex xs4 sm3 md3>
                                                            <div><strong style="color:red;padding-right:10px;">السعر
                                                                </strong>
                                                                {{item.price_value}} دينار</div>

                                                        </v-flex>

                                                    </v-layout>



                                                </template>
                                            </v-radio>
                                        </v-card>
                                    </v-flex>




                                </v-layout>





                            </v-radio-group>








                            <v-radio-group required v-else disabled :rules="PackagRules"
                                v-model="editedItem.OwnerSubscriptions.subscription_package_id">
                                <v-layout row wrap pa-4>

                                    <v-flex pt-2 xs12 v-for="item in  subscriptions" :key="item.id">
                                        <v-card style="padding:10px;width:100%">

                                            <v-radio disabled
                                                :label=" item.package_name+' | عدد الحجوزات  '+item.reservations_count +'| السعر '+item.price_value+'دينار'"
                                                :value="item.id">


                                                <template v-slot:label>

                                                    <v-layout row wrap>
                                                        <v-flex xs2 md2 sm2 pr-5>
                                                            <div style="text-align:right;"> <strong
                                                                    style="color:green">{{item.package_name}} </strong>
                                                            </div>
                                                        </v-flex>

                                                        <v-flex pr-3 xs5 md3 sm3>
                                                            <div><span style="font-weight:bold">عدد الحجوزات
                                                                </span> <strong style="color:blue;padding-right:10px">
                                                                    {{item.reservations_count}} </strong> </div>
                                                        </v-flex>

                                                        <v-flex xs1 md3 sm3>

                                                        </v-flex>

                                                        <v-flex xs4 sm3 md3>
                                                            <div><strong style="color:red;padding-right:10px;">السعر
                                                                </strong>
                                                                {{item.price_value}} دينار</div>

                                                        </v-flex>

                                                    </v-layout>



                                                </template>
                                            </v-radio>
                                        </v-card>
                                    </v-flex>




                                </v-layout>





                            </v-radio-group>

                        </v-flex>


                        <v-layout row wrap pr-5>


                            <v-btn v-if="editedIndex==-1" @click="submit()" color="success" style="margin-left:10px"
                                width="150px">
                                <span v-show="!loading">حفظ</span>
                                <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>
                            </v-btn>


                            <v-btn v-else @click="update()" color="success" style="margin-left:10px" width="150px">
                                <span v-show="!loading">حفظ التعديل</span>
                                <v-progress-circular indeterminate color="white" v-show="loading"></v-progress-circular>
                            </v-btn>
                            <v-btn @click="close()" color="red" dark="" width="150px"> خروج</v-btn>

                        </v-layout>




                    </v-container>


                </v-form>
            </v-card>
        </v-dialog>

        <v-row>

            <v-data-table :headers="headers" :items="ownres" sort-by="calories" class="elevation-1 request_table"
                style="width:96%" :items-per-page="25" :search="search" hide-default-footer="" :loading="loading"
                loading-text="جاري تحميل البيانات">



                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>

                        <v-toolbar-title>
                            <!-- <v-btn text style="font-size:22px;" @click="all()">  التجار</v-btn>
                             -->
                            التجار


                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>



                        <v-layout row wrap style="padding-top: 3%;">
                            <!-- <v-form ref="form_signup" v-model="valid"> -->

                            <v-flex xs12 md3 sm3>



                                <v-text-field background-color="#eeeeee" ref="name" v-model="owner_phonex" height="40"
                                    placeholder="رقم هاتف التاجر" required>
                                </v-text-field>



                            </v-flex>

                            <v-flex xs12 md1 sm1 pr2>


                                <v-btn flat icon @click="Search_phone()">
                                    <v-icon>fas fa-search</v-icon>
                                </v-btn>
                            </v-flex>

                            <!-- </v-form> -->

                            <v-flex xs12 md3 sm3 pr-7>
                                <v-select dense="" :items="owners_status" item-value="id" item-text="name" outlined=""
                                    placeholder="حاله التاجر" v-model="owner_status" @change="owners_statuss()">
                                </v-select>

                            </v-flex>


                            <v-flex xs12 md3 sm3 pr-7>
                                <v-select dense="" :items="category" item-value="id" item-text="name" outlined=""
                                    placeholder="قسم التاجر" v-model="filters.owner_cat"></v-select>
                            </v-flex>

                            <v-flex xs12 md1 sm1 pr-5>
                                <v-btn style="font-size:18px;" @click="all()"> الكل</v-btn>

                            </v-flex>


                        </v-layout>



                        <!-- <v-spacer></v-spacer> -->
                        <v-row justify="end" style="margin-top:20px;">
                            <div style="width:auto">

                                <v-btn color="primary" dark class="mb-2"
                                    @click="dialog=true;editedIndex=-1;add_owner()">
                                    <span style="margin-left:5px;font-family:'cairo_Semi'">اضافه تاجر</span>
                                    <v-icon size="15">fas fa-plus</v-icon>

                                </v-btn>



                            </div>



                        </v-row>

                    </v-toolbar>
                </template>


                <!-- <template v-slot:bottom>
                    
                   <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle="" :length="pageCount">
      </v-pagination>
                </template> -->
                <template v-slot:item.created_at="{ item }">

                    <span>{{getDateOnly(item.created_at)}}</span>

                </template>



                <template v-slot:item.status="{ item }">





                    <v-row wrap justify="center">
                        <!-- <v-icon size="20" style="margin-right:5px" color="primary">fas fa-clock</v-icon> -->

                        <v-chip class="res_pinding_chip" dark="" :color="item.user.status.status_color">
                            <span style="color:#fff"> {{item.user.status.status_name_ar}}
                                <v-icon left size="12"> {{item.user.status.status_icon}}</v-icon>
                            </span>

                        </v-chip>
                    </v-row>






                    <!-- <span v-if="item.user.status.id==1">مفعل</span>
                    <span v-else>متوقف</span> -->
                </template>






                <!-- edit reservation -->
                <!-- <template v-slot:item.action="{ item }"> -->
                <!-- <v-btn text icon @click="dialog=true;item_selected=item">
                        <v-icon size="50">fas fa-edit</v-icon>
                    </v-btn> -->
                <!-- 
                    <v-btn v-if="item.user.status_id==1" color="#04355b" dark style="height:30px"
                        @click="changeStatus(item)">
                        ايقاف التجار
                    </v-btn>
                    <v-btn v-else dark="" color="green" style="height:30px" @click="changeStatus(item)">
                        تفعيل التجار
                    </v-btn>

                </template> -->

                <template v-slot:item.edit="{ item }">

                    <v-btn icon="" @click="editItem(item)">
                        <v-icon>fas fa-edit</v-icon>
                    </v-btn>

                </template>



            </v-data-table>

        </v-row>
        <v-row>


            <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle=""
                :length="pageCount">
            </v-pagination>
        </v-row>

        <v-dialog v-model="verfy_Dailog" max-width="450px" height="300px" persistent>
            <v-card>
                <v-form v-model="active_form" ref="active_form">
                    <v-container>
                        <span class="mb-4">تم ارسال رقم التفعيل برساله يرجئ التحقق</span>
                        <v-text-field label="ادخل رقم التفعيل" v-model="verfy_code" required counter-value="4"
                            type="number" :rules="verfyRules" autocomplete="off" class="mb-6">
                        </v-text-field>

                        <v-row justify="center">
                            <v-flex xs4 pl-3>
                                <v-btn color="primary" @click="verfyAccount" style=";color:#fff;width:100%">
                                    تفعيل
                                </v-btn>



                            </v-flex>

                            <v-flex xs4>

                                <v-btn color="red" @click="verfy_Dailog=false" style=";color:#fff;width:100%">
                                    خروج
                                </v-btn>


                            </v-flex>

                        </v-row>



                    </v-container>

                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>

</template>


<script>
    const axios = require('axios');
    export default {
        data: () => ({

            owner_status: '',
            ccc: '',
            owners_status: [{
                    id: 0,
                    name: 'الكل'
                }, {
                    id: 1,
                    name: 'المفعلين'
                },

                {
                    id: 2,
                    name: 'غير مفعل'
                },

                {
                    id: 24,
                    name: 'معلق'
                },




            ],

            verfy_Dailog: false,
            verfy_code: '',
            active_form: false,
            signup_user_id: '',
            today: '2019-01-08',
            selectedType: 'month',
            verfyRules: [
                (v) => !!v || 'يجب ادخال كود التفعيل',
                (v) => (v.length == 6) || ' يجب ان يكون كود التفعيل 6 ارقام',
                (v) => /^\d+$/.test(v) || 'ارقام فقط'

            ],

            PackagRules: [
                (v) => !!v || 'يجب اختيار حزمه',

            ],


            page: 1,
            pageCount: 0,
            current_page: 1,
            last_page: 0,

            //loading: false,
            user_info: {},
            show1: false,
            show2: false,
            category: [],
            //   owner:{},

            owners: {
                name: "",
                email: "",
                password: "",
                phone: "",
                company_name: "",
                category_id: ""


            },
            editedItem: {
                OwnerSubscriptions: {
                    subscription_package_id: ''
                },
                user: {
                    created_at: "",
                    full_name: "",
                    id: "",
                    mobile_token: "",
                    remember_token: "",
                    role_id: "",
                    password_confirmation: "",
                    status_id: "",
                    updated_at: "",
                },

                owner_category: [{
                    id: "",
                    category_id: "",
                    owner_id: "",
                    status_id: "",
                    created_at: "",

                }],

                owner_description: "",
                owner_email: "",
                password: "",
                user_phone: "",
                owner_barnd_name: "",
                owner_phone: "",
                status_id: "",
                owner_type_id: "",
                province_id: "",
                category_id: "",
                long: "66",
                lat: "32",

            },

            calander_dialog: false,
            err_show: false,
            error: '',
            search: '',
            urL: 'https://api.ahjez-ely.com',
            dialog: false,
            items: [],
            images: [],
            passwordRules: [
                v => !!v || 'يجب ادخال الباسورد',
                (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
                (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام'
            ],
            passwordConfirmationRules: [
                v => !!v || 'يجب ادخال الباسورد',
                (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
                (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام',

            ],



            nameRules: [
                (v) => !!v || 'يجب ادخال الاسم',

            ],





            phoneRules: [
                (v) => !!v || 'يجب ادخال رقم الهاتف',
                (v) => v.length == 11 || 'يجب ادخال رقم هاتف صحيح',

            ],





            emailRules: [



                (v) => (v.length < 1 || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v)) ||
                'ألايميل غير صحيح',


            ],



            PasswordRules: [
                (v) => !!v || 'يجب ادخال الباسورد',
                v => v.length > 3 || ' يجب ان لا يقل عن 4',

            ],

            imgname: '',
            ownres: [],
            filters: {
                owner_phone: "",
                status_id: "",
                owner_cat: ""
            },

            item_selected: {},
            itemOwnerSubscriptions:{},
            loding_accept: false,
            loding_cancel: false,
            headers: [{
                    text: 'رقم التعريف',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'اسم التاجر',
                    align: 'center',
                    sortable: false,
                    value: 'user.full_name',
                },
                {
                    text: 'اسم الشركة',
                    align: 'center',
                    sortable: false,
                    value: 'owner_barnd_name',
                },
                {
                    text: 'رقم الهاتف',
                    align: 'center',
                    sortable: false,
                    value: 'owner_phone',
                },

                // { text: 'الوصف', value: 'disc' },
                {
                    text: 'البريد الالكتروني',
                    align: 'center',
                    sortable: false,
                    value: 'owner_email',
                },


                {
                    text: 'تاريخ الاضافة',
                    value: 'created_at',
                    sortable: false
                },



                {
                    text: ' الحالة',
                    value: 'status',
                    align: 'center',
                },
                // {
                //     text: ' ',
                //     value: 'action',
                //     align: 'center',
                // },

                {
                    text: '',
                    value: 'edit',
                    sortable: false
                },


            ],
            editedIndex: -1,

            owner_phonex: '',

            valid: false,
            // page: 1,
            // pageCount: 0,
            // current_page: 0,
            // last_page: 0,
            loading: false,
            subscriptions: [],


        }),



        methods: {

            getsubscriptions() {

                this.$http({
                    method: 'get',
                    url: '/v2/SubscriptionPackages/operation/get',
                    headers: {

                    }

                }).then(response => {

                    this.subscriptions = response.data.data;
                }).catch(error => {
                    error
                })
            },
            Search_phone() {
                this.filters.owner_phone = this.owner_phonex;

            },

            all() {
                this.filters.status_id = '';
                this.filters.owner_phone = '';
                this.filters.owner_cat = '';
                this.Search();
            },
            owners_statuss() {

                if (this.owner_status == 0) {
                    this.filters.status_id = '';
                    this.filters.owner_phone = '';
                    this.filters.owner_cat = '';

                    this.Search();
                } else {
                    this.filters.status_id = parseInt(this.owner_status);
                    this.Search();

                }

            },


            Search() {
                this.loading = true;


                var owner_phone = this.filters.owner_phone;
                // if (this.filters.owner_phone !== "") {
                //     owner_phone= "964" + parseInt(this.filters.owner_phone);
                // } else {
                //      owner_phone = '';
                // }



                var url = '/operation/SearchOwner?filter[user.status.id]=' + this.filters.status_id +
                    '&filter[owner_phone]=' + owner_phone + '&filter[ownerCategory.category_id]=' + this.filters
                    .owner_cat + '&sort=-id&page=' + this
                    .current_page;
                this.loading = true;
                this.$http({
                    method: 'get',
                    url: url,
                    headers: {

                    }

                }).then(response => {
                    this.loading = false;
                    this.ownres = response.data.data;


                    this.last_page = response.data.meta.last_page;
                    this.pageCount = response.data.meta.last_page;


                }).catch(error => {
                    error
                    //this.$swal('خطاء', "خطاء بالاتصال", 'error')
                }).finally(s => {
                    s,
                    this.loading = false;
                });
            },
            add_owner() {
                this.editedItem = {

                    user: {
                        created_at: "",
                        full_name: "",
                        id: "",
                        mobile_token: "",
                        remember_token: "",
                        role_id: "",
                        status_id: "",
                        updated_at: "",
                    },

                    owner_category: [{
                        id: "",
                        category_id: "",
                        owner_id: "",
                        status_id: "",
                        created_at: "",

                    }],

                    owner_description: "",
                    owner_email: "",
                    password: "",
                    user_phone: "",
                    owner_barnd_name: "",
                    owner_phone: "",
                    status_id: "",
                    owner_type_id: "",
                    province_id: "",
                    category_id: "",
                    long: "66",
                    lat: "32",

                };
            },

            editItem(item) {
                this.editedIndex = 1;
                this.itemOwnerSubscriptions=item.OwnerSubscriptions ;
                this.editedItem = Object.assign({}, item);
                if (item.OwnerSubscriptions == null) {
                    this.editedItem.OwnerSubscriptions = {
                        'subscription_package_id': null
                    }


                }


                this.dialog = true;

            },

            submit() {







                if (this.$refs.form.validate() && !this.loading) {



                    var data = {

                        full_name: this.editedItem.user.full_name,
                        //       user_email: this.editedItem.owner_email,
                        password: this.editedItem.password,
                        owner_brand_name: this.editedItem.owner_barnd_name,
                        user_phone: "964" + parseInt(this.editedItem.owner_phone),
                        owner_barnd_name: this.editedItem.company_name,
                        owner_phone: "964" + parseInt(this.editedItem.owner_phone),
                        owner_type_id: 1,
                        province_id: "1",
                        category_id: this.editedItem.owner_category.category_id,
                        long: "66",
                        lat: "32",
                        password_confirmation: this.editedItem.password_confirmation,
                        owner_image: this.images,
                        images: this.images


                    };
                    this.loading = true;
                    // this.description=JSON.stringify(data);
                    this.$http({
                            method: 'post',
                            url: "/auth/v2/signUpOthers",
                            data: data,
                            headers: {

                            }
                        })
                        .then(response => {
                            response,



                            this.signup_user_id = response.data.id;

                            this.dialog = false;
                            this.verfy_Dailog = true;
                            this.Search();

                            //   this.$swal('', "   تم اضافة تاجر", 'success')



                        })
                        .catch(error => {
                            this.verfy_Dailog = true;
                            if (error.response) {
                                if (error.response.data.data.user_phone ==
                                    "The user phone has already been taken.") {
                                    this.$swal('', " رقم الهاتف مسجل", 'error')
                                } else {
                                    this.$swal('', "  لم تم اضافة تاجر ", 'error')
                                }

                            }


                        }).finally(() => {

                            this.loading = false;
                        });

                }


            },


            getCategory() {

                var url = "/v2/Categories";
                axios.get(url).then(response => {
                    this.category = response.data.data;



                })
            },


            verfyAccount() {
                if (this.$refs.active_form.validate()) {

                    var data = {



                        user_id: this.signup_user_id,


                    };

                    this.$http({
                            method: 'post',
                            url: '/operation/OwnerAccountActivate/' + this.verfy_code,
                            data: data,
                            headers: {
                                //  Authorization: 'Bearer '+this.token
                            }

                        }).then(response => {
                            if (response.data.success == true) {
                                this.$swal('',
                                    " تم تفعيل حساب التاجر",
                                    'success')
                                this.verfy_Dailog = false;
                                this.dialog = false;
                                // this.signup_user_id='';
                                this.Search();

                            }

                        })
                        .catch(error => {
                            // this.signup_user_id='';

                            if (error.response.data.success == false) {
                                this.$swal('', 'رمز التفعيل خطاء', 'error')
                            }

                        })


                }
            },

            close() {
                this.dialog = false;
                this.Search();
            },


            update() {

                const Swal = require('sweetalert2');



                Swal.fire({
                    title: "هل انت متاكد من تغير معلومات التاجر ؟ ",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم',
                    cancelButtonText: 'خروج'

                }).then((result) => {
                    if (result.value) {


                        var data = {

                            full_name: this.editedItem.user.full_name,
                            owner_brand_name: this.editedItem.owner_barnd_name,
                            owner_type_id: 1,
                            user_id: this.editedItem.user.id,
                            category_id: this.editedItem.owner_category.category_id,
                            status_id: this.editedItem.user.status.id,
                            subscriptions_id: this.editedItem.OwnerSubscriptions.subscription_package_id

                        };
                        this.loading = true;
                        this.$http({
                                method: 'post',
                                url: "operation/UpdateOwnerInfo",
                                data: data,
                                headers: {

                                }
                            })
                            .then(response => {
                                response,

                                this.dialog = false;
                                this.$swal('', "   تم تعديل معلومات التاجر  ", 'success')

                                // item.user.status_id = item.user.status_id == 1 ? 2 : 1;

                                // item.user.status_id == 1 ? this.$swal('', "   تم ايقاف التاجر", 'success') :
                                // this.$swal('', "   تم  تفعيل التاجر",
                                //         'success');

                                this.Search();





                            })
                            .catch(() => {


                                this.$swal('', " حدث خطاء", 'error')
                            }).finally(() => {

                                this.loading = false;
                            });




                    }
                })

            },
            changeStatus(item) {






                const Swal = require('sweetalert2');



                Swal.fire({
                    title: "هل انت متاكد من الامر ؟",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم',
                    // cancelButtonText: 'خروج'

                }).then((result) => {
                    if (result.value) {


                        var data = {


                            owner_id: item.id,
                            status_id: item.user.status.id,



                        };
                        this.loading = true;
                        // this.description=JSON.stringify(data);
                        this.$http({
                                method: 'post',
                                url: "operation/OwnerChangeStatus",
                                data: data,
                                headers: {

                                }
                            })
                            .then(response => {
                                response,
                                this.dialog = false;

                                item.user.status.id = item.user.status.id == 1 ? 2 : 1;

                                item.user.status.id == 1 ? this.$swal('', "   تم ايقاف التاجر", 'success') :
                                this.$swal('', "   تم  تفعيل التاجر",
                                        'success');

                                this.Search();





                            })
                            .catch(() => {


                                this.$swal('', " حدث خطاء", 'error')
                            }).finally(() => {

                                this.loading = false;
                            });




                    }
                })



            },
            cat_selectFunc(item) {

                this.owners.category_id = item.id;

            },
            deleteItem(item) {
                //  const index = this.items.indexOf(item)
                this.cancelReservation(item);




            },



            getmore() {},


            // Search() {




            //     var url = "/operation/getAllOwners?page=" + this.current_page;
            //     this.loading = true;
            //     this.$http({
            //         method: 'get',
            //         url: url,
            //         headers: {

            //         }

            //     }).then(response => {
            //         this.ownres = response.data.data.data;

            //         this.last_page = response.data.data.last_page;
            //         this.pageCount = response.data.data.last_page;

            //     }).catch(error => {
            //         error
            //         //this.$swal('خطاء', "خطاء بالاتصال", 'error')
            //     }).finally(s => {
            //         s,
            //         this.loading = false;
            //     });
            // },
            getMoreitems() {

                if (this.current_page <= this.last_page) {
                    this.current_page = this.page;
                    this.Search();
                }



            },

            pickFile() {

                this.$refs.image.click()
            },

            onFilePicked(e) {

                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                        this.images = [];
                        this.images.push(fr.result);
                        this.imgname = files[0].name;


                    })

                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },

            getDateOnly(date) {
                if (date != null) {
                    return date.split("T")[0];
                } else {
                    return "--------"
                }

            },
            getInfo() {


                this.user_info = this.$cookies.get('admin_details');
            }

        },

        mounted() {
            this.getInfo();
            this.getsubscriptions();
            this.Search();
            this.getCategory();

        },


        computed: {

            selected: function () {

                return this.getMoreitems();
            }


        },


        watch: {
            selected: 'search by sub_cat_id',
        },

    }
</script>